<template>
	<Page
		ref="page"
		hide-menu
		:background-color="event.pages.Login.backgroundColor"
		poster="https://delivery.eventcdn.net/events/638/1957b356-dff4-4105-acf0-d45286bb5369/4309pandora-background.png"
	>
		<v-container style="min-height: 90vh">
			<v-row style="min-height: 90vh" align="center">
				<v-col cols="12" md="6">
					<div class="d-flex justify-center pb-8">
						<Logo></Logo>
					</div>

					<v-card class="glass" max-width="790px">
						<v-card-text class="px-8">
							<h4 class="text-center white--text">
								JANUARY 24, 2022, 12:00PM EST
							</h4>
							<h2 class="text-center white--text">
								PANDORA STATE OF MIND
							</h2>

							<template v-if="error">
								<p class="error--text text-center">
									Login failed. Username & Password don't
									match.
								</p>
							</template>

							<p class="text-center white--text mb-n2 pt-8">
								LOG IN
							</p>

							<lh-form
								v-model="form.data"
								:schema="form.schema"
							></lh-form>

							<div>
								<v-btn
									text
									color="white"
									:to="{ name: 'Register' }"
									>Not Registered Yet?
									<span
										style="text-decoration: underline"
										class="pl-4"
										>Register Here</span
									></v-btn
								>
							</div>

							<div class="d-flex justify-end pt-8">
								<v-btn
									large
									class="primary--shadow"
									@click="login({ data: form.data })"
									>Join the Event</v-btn
								>
							</div>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</Page>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
	name: "Login",

	data() {
		return {
			error: false,
			time: "20210914T150000Z",
			showPassword: false,
			password: "Password",

			form: {
				data: {},
				schema: {
					global: {
						fields: {
							solo: true,
						},
					},
					groups: [
						{
							fields: [
								{
									name: "username",
									type: "email",
									label: "Username",
									placeholder: "Username",
									required: true,
								},
							],
						},
						{
							fields: [
								{
									name: "password",
									type: "password",
									label: "Password",
									placeholder: "Password",
									required: true,
								},
							],
						},
					],
				},
			},

			accept_terms: null,
			resetPasswordUrl: `https:/${process.env.VUE_APP_BACKEND_URL}password/reset/`,
		};
	},

	computed: {
		...mapGetters(["me", "isAuthenticated"]),
		...mapState(["event"]),
	},

	watch: {
		"$store.getters.isAuthenticated": async function (newValue, oldValue) {
			console.log(
				"isAuthenticated change:",
				newValue,
				"oldValue:",
				oldValue
			);
			if (newValue) {
				await this.onLogin();
			}
		},
	},

	methods: {
		navigate() {
			const next = this.$route.query?.next;
			if (next) {
				this.$router.push(next);
			} else if (this.$store.getters.isAdmin) {
				this.$router.push({ name: "Home" });
			} else {
				this.$router.push({ name: "Home" });
			}
		},
		async login({ data: credentials }) {
			this.error = false;
			try {
				await this.$store.dispatch("login", credentials);
			} catch (e) {
				this.error = true;
				console.debug("Login error:", e);
			}
		},
		async onLogin() {
			// Track

			await this.$livehouse.eventcdn.beacon.track("login", {
				category: "User",
			});

			// Navigate
			this.navigate();
		},
	},
};
</script>

<style lang="scss" scoped>
#login-button {
	float: right;
}
</style>
